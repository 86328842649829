import React, { useState } from 'react';

import { useForm } from "react-hook-form";
import axios from 'axios';

const SPLETNE_SI_API_KEY = 'OwNFYPpC46dXTGeYzVCLQqiFXcS0myu8';

function ContactForm({setFormSuccessStatus}) {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => {
        axios.post('https://services.spletne.si/rudnik-sitarjevec/prijava',{
            data,
            key: SPLETNE_SI_API_KEY
        }).then((res) => {
            setFormSuccessStatus(true);
        }).catch(err => {
            console.log(err);
            setFormSuccessStatus(false);
        })
    };

    return (
        <div className="contact-form--1" id="prijava">
            <div className="row">
                <div className="col-12">
                    <div className="form-wrapper">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label htmlFor="name">
                                <input
                                    {...register('name', {
                                        required: true,
                                    })}
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Vpišite vaše ime in priimek*"
                                />
                                {errors.name && errors.name.type === "required" && (
                                    <span className="invalid-form-data">
                                        To polje je za nadaljevanje potrebno izpolniti.
                                    </span>
                                )}
                            </label>

                            <label htmlFor="email">
                                <input
                                    {...register('email', {
                                        required: true
                                    })}
                                    type="text"
                                    id="email"
                                    name="email"
                                    placeholder="Vpišite vaš email naslov*"
                                />
                                {errors.email && errors.email.type === "required" && (
                                    <span className="invalid-form-data">
                                        To polje je za nadaljevanje potrebno izpolniti.
                                    </span>
                                )}
                            </label>

                            <label htmlFor="phone">
                                <input
                                    {...register('phone', {
                                        required: true
                                    })}
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    placeholder="Vpišite vašo telefonsko številko*"
                                />
                                {errors.phone && errors.phone.type === "required" && (
                                    <span className="invalid-form-data">
                                        To polje je za nadaljevanje potrebno izpolniti.
                                    </span>
                                )}
                            </label>

                            <label htmlFor="tickets">
                                <select
                                    {...register('tickets', {
                                        required: true
                                    })}
                                    id="tickets"
                                    name="tickets"
                                >
                                    {[...Array(20)].map((_, index) => (
                                        <option key={index + 1} value={index + 1}>
                                            {index + 1}
                                        </option>
                                    ))}
                                </select>
                                {errors.tickets && errors.tickets.type === "required" && (
                                    <span className="invalid-form-data">
                                        To polje je za nadaljevanje potrebno izpolniti.
                                    </span>
                                )}
                            </label>

                            <label htmlFor="special">
                                <input
                                    {...register('special', {})}
                                    type="text"
                                    id="special"
                                    name="special"
                                    placeholder="V primeru, da se morate določenih živil izogibati, jih vpišite tukaj"
                                />
                            </label>

                            <button className="btn-default size-xl mt-4" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Prijavite se</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactForm;