import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {Link} from "gatsby";
import {FiCheck} from "react-icons/fi";

const scrollToPrijava = () => {
    const prijava = document.getElementById('prijava');

    // Scroll to the start of the element
    prijava.scrollIntoView({ behavior: 'smooth', block: 'start' });
}

function CuisineDescription(props) {
    return (
        <>
            <div className="about-wrapper ptb--120">
                <div className="container">
                    <div className="row align-items-center text-center text-lg-left">

                        <div className="col-lg-6 col-md-12">
                            <div className="">
                                <div className="section-title">
                                    <h3 className="title">Cena vključuje: </h3>

                                    <div className="mt--30">
                                        <ul className="list-style--1">
                                            <li>
                                                <FiCheck />
                                                Izposojo čelade in tople odeje.
                                            </li>

                                            <li>
                                                <FiCheck />
                                                Kozarec okusne penine ob dobrodošlici in pozdrav iz kuhinje.
                                            </li>

                                            <li>
                                                <FiCheck />
                                                5-hodno večerjo z vinsko spremljavo izpod ekipe Mitje Zadravca Ribiča iz Krčme na gradu Bogenšperk.
                                            </li>

                                            <li>
                                                <FiCheck />
                                                Izvirne lokalne rudarske zgodbe.
                                            </li>
                                            <li>
                                                <FiCheck />
                                                Vodenje po Izvoznem rovu.
                                            </li>
                                            <li>
                                                <FiCheck />
                                                Darilo presenečenja.
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 col-xl-6 mt-4">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../assets/images/rudnik/rudarski-hod-1.jpg"
                                    alt="Sitarjevška malca in Škratov šmorn"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-wrapper ptb--80 bg-primary text-white">
                <div className="container">
                    <div className="row justify-content-center mx-auto">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="text-white mx-auto">
                                <h2 className="title text-center text-white">Pomembno!</h2>
                                <h3 className="title text-center lead-2 text-white">
                                    V rudniku je samo 10 stopinj!
                                    Modro izberite primerno obutev in topla oblačila.
                                </h3>
                                <h2 className="title text-center mt-4 animated-element tada">Število gostov je omejeno na 30!</h2>

                                <div className="mx-auto text-center">
                                    <button onClick={scrollToPrijava} className="btn-default btn-border btn-large btn-white mt--20">Prijavite se</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CuisineDescription;