import React, { useState } from "react";
import { Link } from "gatsby";
import { FiCheck } from "react-icons/fi";
import MineCulinaryContactForm from "./MineCulinaryContactForm";

function MineCulinaryForm(props) {
    const [messageSent, setMessageSend] = useState(false);

    return (
        <div className="rn-contact-top-area ptb--120 bg_color--3 mx-auto" id="prijava">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8">
                        <div className="">
                            <div className="section-title mb-5 text-center">
                                <h2 className="title text-center">Kako poteka prijava?</h2>

                                <div className="description mb-2">
                                    Kliknite na gumb <strong>»PRIJAVITE SE«</strong> in <strong>izpolnite obrazec</strong>. Nato boste prejeli predračun, ki ga poravnate.
                                    Organizator po prejemu dokazila o plačani storitvi izda vrednostni bon za dogodek Sitarjevški hodi.
                                </div>
                                <div className="description mb-2">
                                    Ob prijavi  vaša rezervacija velja 3 delovne dni. V tem času je potrebno poravnati račun.
                                    Račun boste prejeli po  e-pošti. S plačilom računa je potrjena rezervacija.
                                </div>

                                <div className="description mb-5 mt-3" style={{fontSize: "0.925rem"}}>
                                    <strong>Turistično informacijski center Litija – TIC Litija.</strong> <br />
                                    Valvazorjev trg 10, 1270 Litija,
                                    info@visitlitija.si,
                                    051 312 739,
                                    070 700 484.
                                </div>

                                    {messageSent ? (
                                    <p>
                                        Hvala za vaše sporočilo. Odgovorili vam
                                        bomo v najkrajšem možnem času.
                                    </p>
                                ) : (
                                    <MineCulinaryContactForm
                                        setFormSuccessStatus={(value) =>
                                            setMessageSend(value)
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MineCulinaryForm;
